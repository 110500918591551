<template>
    <header :class="{'scrolled-nav' : scrollPosition }">
        <nav>
            <ul v-show="!mobile" class="navigation">
                <li><router-link class="mx-2 pb-1" to="/">Portfolio</router-link></li>
                <li><router-link class="mx-2 pb-1" to="/bio">Bio</router-link></li>
                <li><router-link class="mx-2 pb-1" to="/contact">Contact</router-link></li>
            </ul>

            <div class="icon">
                <i @click="toggleMobileNav" v-show="mobile" class="cursor-pointer" :class="{'icon-active' : mobileNav}">
                    <font-awesome-icon class="mx-2" icon="fa-solid fa-grip-lines"
                    />
                </i>
            </div>

            <transition name="mobile-nav">
                <ul v-show="mobileNav" class="dropdown-nav py-20 px-5 border shadow-xl">
                    <div class="flex justify-start ml-20">
                        <font-awesome-icon class="cursor-pointer h-7 w-auto" icon="fa-solid fa-x" @click="toggleMobileNav" />
                    </div>
                    
                    <div class="pt-10 pl-10">
                        <li><router-link to="/" class="text-2xl" @click="toggleMobileNav">Portfolio</router-link></li>
                        <li><router-link to="/bio" class="text-2xl" @click="toggleMobileNav">Bio</router-link></li>
                        <li><router-link to="/contact" class="text-2xl" @click="toggleMobileNav">Contact</router-link></li>

                        <li class="pt-10">
                            <a
                            href="mailto:info@kevinchai.com"
                            target="_blank"
                            class="hover:border-none hover:opacity-70 mr-5"
                            @click="toggleMobileNav"
                            >
                            <font-awesome-icon class="mx-2 h-8" icon="fa-solid fa-envelope" />
                            </a>
                            <a
                            href="https://www.linkedin.com/in/chaikevin/"
                            target="_blank"
                            class="hover:border-none hover:opacity-70"
                            @click="toggleMobileNav"
                            >
                            <font-awesome-icon class="mx-2 h-8" icon="fa-brands fa-linkedin-in" />
                            </a>
                        </li>
                    </div>
                </ul>
            </transition>
        </nav>
    </header>
</template>

<script>
export default {
    name: "navigation",
    data() {
        return {
            scrollPosition: null,
            mobile: false,
            mobileNav: false,
            windowWidth: null
        };
    },

    created() {
        window.addEventListener('resize', this.checkScreen);
        this.checkScreen();
    },

    props: {
    isSidebarOpen: {
      required: true,
      default: false,
      type: Boolean
    }
    },

    methods: {
    toggleMobileNav() {
        this.mobileNav = !this.mobileNav;
    },

    checkScreen(){
        this.windowWidth = window.innerWidth;
        if (this.windowWidth <= 750){
            this.mobile = true;
            return;
        }
        this.mobile = false;
        this.mobileNav = false;
        return;
    }
    }
}
</script>

<style scoped>
    header {
        z-index: 99;
        transition: .5s ease all;
    }

    nav {
        display: flex;
        flex-direction: row;
        padding: 12px 0;
        transition: .5s ease all;
        width: 90%;
        margin: 0 auto;
        position: relative;
        @media(min-width: 1140px) {
            max-width: 1140px;
        };
    }

    .navigation {
        display: flex;
        align-items: center;
        flex: 1;
        justify-content: flex;
    }

    a:hover {
        border-bottom-width: 2px;
    }

    .icon {
        display: flex;
        align-items: center;
        height: 100%;
    }

    .icon-active {
        transform: rotate(180deg);
    }

    .dropdown-nav {
        display: flex;
        flex-direction: column;
        position: fixed;
        width: 100%;
        height: 100%;
        background-color: #fff;
        top: 0;
        left: 0;
    }

    .dropdown-nav li{
        margin-left: 30px;
        margin-top: 20px;
        text-align: left;
    }

    .dropdown-nav li link{
        color: #000;
    }

    .mobile-nav-enter-active,
    .mobile-nav-leave-active {
        transition: .5s ease all;
    }

    .mobile-nav-enter-from,
    .mobile-nav-leave-to {
        transform: translateX(-100%);
    }

    .mobile-nav-enter-to {
        transform: translateX(0);
    }
</style>