<template>
    <div class="flex w-full min-h-[500px] justify-start items-center -mt-20">
        <div class="container w-full h-full text-left 2xl:ml-40">
            <h1 class="w-full hover:underline cursor-default mb-5">Marketing</h1>
            <h1 class="w-full text-gray-500 hover:underline cursor-default mb-5">Web Design</h1>
            <h1 class="w-full text-gray-400 hover:underline cursor-default">Video</h1>
        </div>
    </div>
</template>

<style scoped>
/* large screen padding */
@media (min-width: 750px) {
    .hero {
      padding-left: 40px;
      margin-top: -100px;
    }
}

/* mobile screen padding */
@media (max-width: 750px) {
    .hero {
      padding-left: 10px;
      margin-top: -20px;
    }
}
</style>