<template>
    <div class="flex w-full min-h-full grid grid-cols-12 py-10">
        <div class="w-full col-span-12 grid grid-cols-12 gap-2 sm:gap-10">
            <div class="relative col-span-12 md:col-span-6 lg:col-span-4">
                <router-link to="hoyts" class="highlight">
                    <div class="caption">
                        <h3 class="pb-3">Hoyts (Practice Project)</h3>
                        <ul>Data Analysis</ul>
                    </div>
                </router-link>
                <img src="@/static/portfolio/hoyts/hoyts-logo.webp" class="object-scale-down w-full h-full" />
            </div>

            <div class="relative col-span-12 md:col-span-6 lg:col-span-4">
                <router-link to="swanston" class="highlight">
                    <div class="caption">
                        <h3 class="pb-3">Swanston Coffee (Practice Project)</h3>
                        <ul>Social Media Marketing</ul>
                        <ul>Content Creation</ul>
                    </div>
                </router-link>
                <img src="@/static/portfolio/swanston/swanston-logo.png" class="object-scale-down w-full h-full" />
            </div>

            <div class="relative col-span-12 md:col-span-6 lg:col-span-4">
                <router-link to="nutrition-blog" class="highlight">
                    <div class="caption">
                        <h3 class="pb-3">Nutrition Blog</h3>
                        <ul>Blog Writing</ul>
                    </div>
                </router-link>
                <img src="@/static/portfolio/nutrition-blog/fruits-vegies.jpg" class="object-scale-down w-full h-full" />
            </div>

            <div class="relative col-span-12 md:col-span-6 lg:col-span-4">
                <router-link to="qtac" class="highlight">
                    <div class="caption">
                        <h3 class="pb-3">QTAC</h3>
                        <ul>Marketing Collateral Design</ul>
                    </div>
                </router-link>
                <img src="@/static/portfolio/qtac/qtac-brochure-1.webp" class="object-scale-down w-full h-full" />
            </div>

            <div class="relative col-span-12 md:col-span-6 lg:col-span-4 hidden">
                <router-link to="wavely" class="highlight">
                    <div class="caption">
                        <h3 class="pb-3">Wavely</h3>
                        <ul>Web Design</ul>
                        <ul>Web Development</ul>
                        <ul>Social Media</ul>
                    </div>
                </router-link>
                <img src="@/static/portfolio/wavely/twitter-ad.png" class="object-cover w-full h-full" />
            </div>

            <div class="relative col-span-12 md:col-span-6 lg:col-span-4">
                <router-link to="editor" class="highlight">
                    <div class="caption">
                        <h3 class="pb-3">Video Editor</h3>
                        <ul>Video Editing</ul>
                        <ul>Colour Grading</ul>
                    </div>
                </router-link>
                <img src="@/static/portfolio/editor/editor-thumbnail.png" class="object-cover w-full h-full" />
            </div>

            <div class="relative col-span-12 md:col-span-6 lg:col-span-4">
                <router-link to="mtp" class="highlight">
                    <div class="caption">
                        <h3 class="pb-3">Milk Tea Protein</h3>
                        <ul>Logo Design</ul>
                        <ul>Packaging Design</ul>
                    </div>
                </router-link>
                <img src="@/static/portfolio/mtp/single-beige-bg.png" class="object-scale-down w-full h-full" />
            </div>

            <div class="relative col-span-12 md:col-span-6 lg:col-span-4">
                <router-link to="urban-kimchi" class="highlight">
                    <div class="caption">
                        <h3 class="pb-3">Urban Kimchi</h3>
                        <ul>Logo Design</ul>
                        <ul>Packaging Design</ul>
                    </div>
                </router-link>
                <img src="@/static/portfolio/urban-kimchi/uk-logo.png" class="object-scale-down w-full h-full" />
            </div>
        </div>
    </div>
</template>

<style scoped>

.highlight{
    position: absolute;
    width: 100%;
    height: 100%;
    margin-left: -50%;
    background-color: white;
    opacity: 0;
}

.highlight:hover{
    opacity: 0.9;
    transition: .5s ease all;
    cursor: pointer;
    border-bottom: hidden;
}

.caption {
    position: absolute;
    height: 50%;
    width: 100%;
    left: 50%;
    margin-left: -50%;
    top: 50%;
    margin-top: -40px;
}
</style>