<template>
    <nav class="flex w-full justify-between py-5 items-center border-b">
        <Menu class="w-1/3 md:pl-5 lg:pl-20" />

        <div class="w-1/3 flex flex-col items-center">
          <router-link to="/" class="logo flex items-start gap-5">
            <img src="@/static/logo.png" class="w-5 h-auto" />
            <span class="hidden sm:flex">KEVIN CHAI</span>
          </router-link>
        </div>

        <div class="w-1/3 hidden sm:block text-right 2xl:text-center md:pr-5 lg:pr-20">
            <a
            href="mailto:kevincschai@gmail.com"
            target="_blank"
            class="hover:border-none hover:opacity-70"
            >
            <font-awesome-icon class="mx-2" icon="fa-solid fa-envelope" />
            </a>
            <a
            href="https://www.linkedin.com/in/chaikevin/"
            target="_blank"
            class="hover:border-none hover:opacity-70"
            >
            <font-awesome-icon class="mx-2" icon="fa-brands fa-linkedin-in" />
            </a>
        </div>
      </nav>
</template>

<script>
import Menu from '@/components/menus/menu.vue'

export default {
  name: 'home',
  components: {
    Menu
  }
}
</script>

<style scoped>

.logo {
  font-weight: 600;
  border-bottom: hidden;
}

</style>