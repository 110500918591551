<template>
  <div class="home min-h-full">
    <Hero />
    <PortfolioShowcase />
    <Skills />
  </div>
</template>

<script>
// @ is an alias to /src
import Hero from '@/components/home/hero.vue'
import PortfolioShowcase from '@/components/home/portfolio-showcase.vue'
import Skills from '@/components/home/skills.vue'

export default {
  name: 'home',
  components: {
    Hero,
    PortfolioShowcase,
    Skills
  }
}
</script>
