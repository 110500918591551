<template>
    <div class="flex w-full min-h-full grid grid-cols-12 gap-2 py-10 md:p-20 2xl:p-40 border-t">

        <h1 class="col-span-12">Skills</h1>

        <div class="w-full col-span-12 grid grid-cols-12 sm:gap-10 py-10 sm:py-20">
            <div class="flex flex-col items-center col-span-6 sm:col-span-4 xl:col-span-3 py-5 gap-5">
                <img src="@/static/portfolio-icons/illustrator.png" class="h-10 w-auto" />
                <h3 class="">Adobe Illustrator</h3>
            </div>

            <div class="flex flex-col items-center col-span-6 sm:col-span-4 xl:col-span-3 py-5 gap-5">
                <img src="@/static/portfolio-icons/photoshop.png" class="h-10 w-auto" />
                <h3>Adobe Photoshop</h3>
            </div>
            
            <div class="flex flex-col items-center col-span-6 sm:col-span-4 xl:col-span-3 py-5 gap-5">
                <img src="@/static/portfolio-icons/canva.png" class="h-10 w-auto" />
                <h3>Canva</h3>
            </div>

            <div class="flex flex-col items-center col-span-6 sm:col-span-4 xl:col-span-3 py-5 gap-5">
                <img src="@/static/portfolio-icons/google-analytics.png" class="h-10 w-auto" />
                <h3>Google Analytics</h3>
            </div>

            <div class="flex flex-col items-center col-span-6 sm:col-span-4 xl:col-span-3 py-5 gap-5">
                <img src="@/static/portfolio-icons/wordpress.png" class="h-10 w-auto" />
                <h3>WordPress</h3>
            </div>

            <div class="flex flex-col items-center col-span-6 sm:col-span-4 xl:col-span-3 py-5 gap-5">
                <img src="@/static/portfolio-icons/figma.png" class="h-10 w-auto" />
                <h3>Figma</h3>
            </div>

            <div class="flex flex-col items-center col-span-6 sm:col-span-4 xl:col-span-3 py-5 gap-5">
                <img src="@/static/portfolio-icons/html.png" class="h-10 w-auto" />
                <h3>HTML</h3>
            </div>
            
            <div class="flex flex-col items-center col-span-6 sm:col-span-4 xl:col-span-3 py-5 gap-5">
                <img src="@/static/portfolio-icons/css.png" class="h-10 w-auto" />
                <h3>CSS</h3>
            </div>

            <div class="flex flex-col items-center col-span-6 sm:col-span-4 xl:col-span-3 py-5 gap-5">
                <img src="@/static/portfolio-icons/vscode.png" class="h-10 w-auto" />
                <h3>Visual Code Studio</h3>
            </div>

            <div class="flex flex-col items-center col-span-6 sm:col-span-4 xl:col-span-3 py-5 gap-5">
                <img src="@/static/portfolio-icons/vuejs.png" class="h-10 w-auto" />
                <h3>VueJS</h3>
            </div>

            <div class="flex flex-col items-center col-span-6 sm:col-span-4 xl:col-span-3 py-5 gap-5">
                <img src="@/static/portfolio-icons/premiere-pro.png" class="h-10 w-auto" />
                <h3>Adobe Premiere Pro</h3>
            </div>

            <div class="flex flex-col items-center col-span-6 sm:col-span-4 xl:col-span-3 py-5 gap-5">
                <img src="@/static/portfolio-icons/davinci-resolve.png" class="h-10 w-auto" />
                <h3>Davinci Resolve</h3>
            </div>

            <div class="flex flex-col items-center col-span-6 sm:col-span-4 xl:col-span-3 py-5 gap-5">
                <img src="@/static/portfolio-icons/word.png" class="h-10 w-auto" />
                <h3>Microsoft Word</h3>
            </div>

            <div class="flex flex-col items-center col-span-6 sm:col-span-4 xl:col-span-3 py-5 gap-5">
                <img src="@/static/portfolio-icons/powerpoint.png" class="h-10 w-auto" />
                <h3>Microsoft PowerPoint</h3>
            </div>

            <div class="flex flex-col items-center col-span-6 sm:col-span-4 xl:col-span-3 py-5 gap-5">
                <img src="@/static/portfolio-icons/excel.png" class="h-10 w-auto" />
                <h3>Microsoft Excel</h3>
            </div>

            <div class="flex flex-col items-center col-span-6 sm:col-span-4 xl:col-span-3 py-5 gap-5">
                <img src="@/static/portfolio-icons/notion.png" class="h-10 w-auto" />
                <h3>Notion</h3>
            </div>

            <div class="flex flex-col items-center col-span-6 sm:col-span-4 xl:col-span-3 py-5 gap-5">
                <img src="@/static/portfolio-icons/trello.png" class="h-10 w-auto" />
                <h3>Trello</h3>
            </div>

            <div class="flex flex-col items-center col-span-6 sm:col-span-4 xl:col-span-3 py-5 gap-5">
                <img src="@/static/portfolio-icons/python.png" class="h-10 w-auto" />
                <h3>Python</h3>
            </div>
        </div>
    </div>
</template>