<template>
  <TopBar />
  <router-view v-slot="{ Component }">
    <transition name="route" mode="out-in">
      <component :is="Component"></component>
    </transition>
  </router-view>
  <Footer />
</template>

<script>
import TopBar from './components/menus/topbar.vue'
import Footer from './components/footer.vue'

export default {
  components: {
    TopBar,
    Footer
  },
  name: 'MyPortfolio',
  props: {
    msg: String
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  border-bottom: solid;
  border-bottom-width: 2px;
}

/* route transitions */
.route-enter-from {
  opacity: 0;
  transform: translateY(50px)
}
.route-enter-active {
  transition: all .3s ease-out;
}
.route-leave-to {
  opacity: 0;
  transform: translateY(-50px);
}
.route-leave-active {
  transition: all .3s ease-in;
}

</style>
