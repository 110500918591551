<template>
    <div class="flex min-h-[300px] w-full justify-between py-5 items-start border-t pt-20 px-5">
        <div class="w-1/3 flex flex-col items-center gap-5">
            <router-link to="/" class="hover:border-none">
                <img src="@/static/logo.png" class="w-10 h-auto" />
            </router-link>
            <router-link to="/" class="hover:border-none">KEVIN CHAI</router-link>
            <sub>Copyright 2023</sub>
        </div>

        <div class="w-1/3 flex flex-col items-center">
            <ul class="text-left">
                <li class="mb-5"><router-link to="/">Portfolio</router-link></li>
                <li class="mb-5"><router-link to="/bio">Bio</router-link></li>
                <li class="mb-5"><router-link to="/contact">Contact</router-link></li>
            </ul>
        </div>

        <div class="w-1/3 hidden sm:block">
            <div class="mb-5">
                <a
                href="mailto:kevincschai@gmail.com"
                target="_blank"
                class="hover:border-none hover:opacity-70"
                >
                <font-awesome-icon class="mx-2" icon="fa-solid fa-envelope" />
                </a>
                <a
                href="https://www.linkedin.com/in/chaikevin/"
                target="_blank"
                class="hover:border-none hover:opacity-70"
                >
                <font-awesome-icon class="mx-2" icon="fa-brands fa-linkedin-in" />
                </a>
            </div>

            <a
            href="https://icons8.com/"
            target="_blank"
            rel="noopener"
            class=""
            >
                <sub class="">Icons by Icons8</sub>
            </a>
        </div>
    </div>
</template>