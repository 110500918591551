import { createRouter, createWebHistory } from 'vue-router'
import home from '../views/home.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: home,
    meta: {
      title: 'Kevin Chai | Digital Marketer',
      metaTags: [
        {
          name: 'description',
          content: 'The home page of our example app.'
        },
        {
          property: 'og:description',
          content: 'The home page of our example app.'
        }
      ]
    }
  },
  {
    path: '/bio',
    name: 'bio',
    // route level code-splitting
    // this generates a separate chunk (bio.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "bio" */ '../views/bio.vue'),
    meta: {
      title: 'Bio - Kevin Chai | Digital Marketer',
      metaTags: [
        {
          name: 'description',
          content: 'The about page of our example app.'
        },
        {
          property: 'og:description',
          content: 'The about page of our example app.'
        }
      ]
    }
  },
  {
    path: '/contact',
    name: 'contact',
    // route level code-splitting
    // this generates a separate chunk (bio.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/contact.vue'),
    meta: {
      title: 'Contact - Kevin Chai | Digital Marketer',
      metaTags: [
        {
          name: 'description',
          content: 'Contact'
        },
        {
          property: 'og:description',
          content: 'The about page of our example app.'
        }
      ]
    }
  },

  {
    path: '/hoyts',
    name: 'hoyts',
    component: () => import('../views/portfolios/hoyts.vue'),
    meta: {
      title: 'Hoyts - Kevin Chai | Digital Marketer',
      metaTags: [
        {
          name: 'description',
          content: 'The about page of our example app.'
        },
        {
          property: 'og:description',
          content: 'The about page of our example app.'
        }
      ]
    }
  },

  {
    path: '/swanston',
    name: 'swanston',
    component: () => import('../views/portfolios/swanston.vue'),
    meta: {
      title: 'Swanston Coffee - Kevin Chai | Digital Marketer',
      metaTags: [
        {
          name: 'description',
          content: 'The about page of our example app.'
        },
        {
          property: 'og:description',
          content: 'The about page of our example app.'
        }
      ]
    }
  },

  {
    path: '/nutrition-blog',
    name: 'nutrition-blog',
    component: () => import('../views/portfolios/nutrition-blog.vue'),
    meta: {
      title: 'Nutrition Blog - Kevin Chai | Digital Marketer',
      metaTags: [
        {
          name: 'description',
          content: 'The about page of our example app.'
        },
        {
          property: 'og:description',
          content: 'The about page of our example app.'
        }
      ]
    }
  },

  {
    path: '/qtac',
    name: 'qtac',
    component: () => import('../views/portfolios/qtac.vue'),
    meta: {
      title: 'QTAC - Kevin Chai | Digital Marketer',
      metaTags: [
        {
          name: 'description',
          content: 'The about page of our example app.'
        },
        {
          property: 'og:description',
          content: 'The about page of our example app.'
        }
      ]
    }
  },
  {
    path: '/wavely',
    name: 'wavely',
    component: () => import('../views/portfolios/wavely.vue'),
    meta: {
      title: 'Wavely - Kevin Chai | Digital Marketer',
      metaTags: [
        {
          name: 'description',
          content: 'The about page of our example app.'
        },
        {
          property: 'og:description',
          content: 'The about page of our example app.'
        }
      ]
    }
  },
  {
    path: '/editor',
    name: 'editor',
    component: () => import('../views/portfolios/editor.vue'),
    meta: {
      title: 'Editor - Kevin Chai | Digital Marketer',
      metaTags: [
        {
          name: 'description',
          content: 'The about page of our example app.'
        },
        {
          property: 'og:description',
          content: 'The about page of our example app.'
        }
      ]
    }
  },
  {
    path: '/mtp',
    name: 'mtp',
    component: () => import('../views/portfolios/mtp.vue'),
    meta: {
      title: 'Milk Tea Protein - Kevin Chai | Digital Marketer',
      metaTags: [
        {
          name: 'description',
          content: 'The about page of our example app.'
        },
        {
          property: 'og:description',
          content: 'The about page of our example app.'
        }
      ]
    }
  },
  {
    path: '/urban-kimchi',
    name: 'urban-kimchi',
    component: () => import('../views/portfolios/urban-kimchi.vue'),
    meta: {
      title: 'Urban Kimchi - Kevin Chai | Digital Marketer',
      metaTags: [
        {
          name: 'description',
          content: 'The about page of our example app.'
        },
        {
          property: 'og:description',
          content: 'The about page of our example app.'
        }
      ]
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,

  scrollBehavior(to, from, savedPosition) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({ left: 0, top: 0 })
      }, 300)
    })
  },
})

// ... 

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if(nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if(previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if(!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });

    // We use this to track which meta tags we create so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  })
  // Add the meta tags to the document head.
  .forEach(tag => document.head.appendChild(tag));

  next();
});

// ...

export default router
